import React, { useEffect } from "react";
import styles from "./OrderTracking.module.scss";
import { Add, Remove } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { useIntl } from "react-intl";
import { getStatus } from "./trackingUtils";
import { isMobile } from "../../common/utils";
import {
  URL_GLOBAL_PRODUCT,
  URL_MOBILITY_PRODUCT,
} from "../../common/constant";

function OrderTracking(props) {
  const { formatMessage } = useIntl();
  const f = (id, values) => formatMessage({ id }, values);
  const { orderDetail } = props;

  const packages = orderDetail?.orderTracking;
  const initialCollapsed = packages?.reduce((pre, element) => {
    pre[element.shipmentNumber] = true;
    return pre;
  }, {});
  if (packages && packages.length > 0) {
    initialCollapsed[packages[0]?.shipmentNumber] = false;
  }
  const [collapsed, setCollapsed] = React.useState(initialCollapsed || {});
  const orderNumber = orderDetail?.orderNumber;
  const gotoProductDetail = (product) => {
    let reqUrl = "";
    if (isMobile()) {
      reqUrl =
        URL_MOBILITY_PRODUCT +
        `${product.description}/${product.catalogNumber || product.sku}`;
    } else {
      reqUrl = URL_GLOBAL_PRODUCT + `${product.catalogNumber || product.sku}`;
    }
    window.open(reqUrl, "_blank");
  };
  return (
    <div>
      <div className={styles.headWrapper}>
        <div className={styles.head2}>
          <label className={styles.label1}>{f("ORDER_NUMBER")}:</label>
          {orderDetail?.orderSource === "E1订单" && (
            <div className={styles.e1OrderLabel}>{f("E1_ORDER")}</div>
          )}
          {orderDetail?.orderSource === "SAP订单" && (
            <div className={styles.sapOrderLabel}>{f("SAP_ORDER")}</div>
          )}
          <span style={{wordBreak: "break-word"}}>{orderNumber}</span>
          <span className={styles.value1}>
            ({packages.length} {f("SHIPMENTS")})
          </span>
        </div>
        <div className={styles.column2}>
          <label className={styles.label}>{f("ORDER_CreatedDate")}:</label>
          <span className={styles.value}>{orderDetail.orderDate}</span>
        </div>
      </div>
      <div className={styles.packListWrapper}>
        {(!packages || packages.length === 0) && (
          <div className={styles.noDataWrapper}>
            <Alert
              severity="info"
              style={{
                marginTop: "15px",
                fontSize: "16px",
                justifyContent: "center",
              }}
            >
              {f("NOSHIPMENT")}
            </Alert>
          </div>
        )}
        {packages.map((packageItem) => {
          const statusObj = getStatus({
            eventDescription: packageItem.stateDescription,
            eventCode: packageItem.stateCode,
          });
          return (
            <div
              key={packageItem.shipmentNumber}
              className={styles.packItemWrapper}
            >
              <div className={styles.packItemHeader}>
                <div className={styles.row1}>
                  <div className={`${styles.column1}`}>
                    <label className="head5">{f("SHIPMENT_NUMBER")}:</label>
                    <span>{packageItem.shipmentNumber}</span>
                  </div>
                  <div className={styles.column2}>
                    {statusObj.icon && (
                      <img alt={statusObj.desc} src={statusObj.icon}></img>
                    )}
                    {/* {
                      packageItem?.stateCode === 'DTD' ?
                      <div>
                        <label>{packageItem.stateDescription}</label>
                        <span>{packageItem.stateTime}</span>
                      </div>
                      :
                      <span><label>{f('DN_EDT')}:</label>{packageItem.eta}</span>
                    } */}

                    {packageItem?.stateCode !== "DTD" && packageItem?.eta ? (
                      <span>
                        <label>{f("DN_EDT")}:</label>
                        {packageItem?.eta}
                      </span>
                    ) : (
                      <div>
                        <label>{packageItem.stateDescription}</label>
                        <span>{packageItem.stateTime}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.row2}>
                  <span
                    className="App-link"
                    onClick={() => {
                      setCollapsed({
                        ...collapsed,
                        [packageItem.shipmentNumber]:
                          !collapsed[packageItem.shipmentNumber],
                      });
                    }}
                  >
                    {collapsed[packageItem.shipmentNumber] ? (
                      <Add></Add>
                    ) : (
                      <Remove></Remove>
                    )}
                  </span>
                </div>
              </div>
              <div
                className={
                  collapsed[packageItem.shipmentNumber]
                    ? `${styles.packItemContentWrapper} collapsed`
                    : `${styles.packItemContentWrapper}`
                }
              >
                <div className={styles.productItemsWrapper}>
                  <div className="head2 labelMedium">
                    <span>{f("THISSHIPMENT")} </span>(
                    <span>
                      {packageItem?.shipmentLines?.length} {f("ITEMS")}
                    </span>
                    )
                  </div>
                  {packageItem?.shipmentLines?.map((product) => {
                    console.log("product", product);
                    if (!product) return null;
                    const imgSrc = product.productImage
                      ? product.productImage + "-650.jpg"
                      : "/images/default-brand.jpg";
                    const hasChildren =
                      product.productType?.toLowerCase() === "kit" &&
                      product.items &&
                      product.items.length > 0;
                    return (
                      <div className={styles.parentproductWrapper}>
                        <div
                          key={product.catalogNumber}
                          className={styles.productWrapper}
                        >
                          <div className={styles.imgWrapper}>
                            <img alt={product.description} src={imgSrc}></img>
                          </div>
                          <div className={styles.pcontentWrapper}>
                            <p
                              className="primaryLink bold vmargin14"
                              onClick={() => {
                                gotoProductDetail(product);
                              }}
                              dangerouslySetInnerHTML={{
                                __html: product.description,
                              }}
                            ></p>
                            <div>
                              <label className="labelMedium">
                                {f("CATALOGNUMBER")}:
                              </label>
                              <span>{product.catalogNumber}</span>
                              <label
                                className="labelMedium"
                                style={{ paddingLeft: "1rem" }}
                              >
                                {f("QUANTITY")}:
                              </label>
                              <span>{product.quantity}</span>
                            </div>
                          </div>
                        </div>
                        {hasChildren && (
                          <div style={{ paddingLeft: "15px" }}>
                            {product.items.map((child) => {
                              return (
                                <div>
                                  <p className="bold">{child.description}</p>
                                  <div>
                                    <label className="labelMedium">
                                      {f("CATALOGNUMBER")}:
                                    </label>
                                    <span>{child.sku}</span>
                                    <label
                                      className="labelMedium"
                                      style={{ paddingLeft: "1rem" }}
                                    >
                                      {f("QUANTITY")}:
                                    </label>
                                    <span>{child.quantity}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className={styles.shipmentWrapper}>
                  <div className={styles.carrierWrapper}>
                    <div className={styles.row}>
                      {packageItem.expressNumber ? (
                        <div className={styles.column1}>
                          <label className={styles.lable1}>
                            {f("TRACKER_NUMBER")}:
                          </label>
                          <span>{packageItem.expressNumber}</span>
                        </div>
                      ) : null}
                      <div className={styles.column2}>
                        <label>{f("CARRIER_NAME")}:</label>
                        <span>{packageItem.carrierName}</span>
                      </div>
                    </div>
                    {packageItem?.shipperCity &&
                      packageItem?.consigneeCity &&
                      packageItem?.offeringType && (
                        <div className={styles.row}>
                          <div className={styles.column1}>
                            <label className={styles.lable1}>
                              {f("shipperCity_consigneeCity")}:
                            </label>
                            <span>
                              {packageItem.shipperCity + "-"}{" "}
                              {packageItem.consigneeCity}
                            </span>
                          </div>
                          <div className={styles.column2}>
                            <label>{f("offeringType")}:</label>
                            <span>{packageItem.offeringType}</span>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className={styles.timelineHead}>
                    <label>{f("ACTIVITY")}</label>
                  </div>
                  <ul className={styles.timelineWrapper}>
                    {packageItem?.events?.map((timeline, timelineIndex) => {
                      return (
                        <li
                          key={timeline.eventTime}
                          className={styles.timelineItem}
                        >
                          <div className={styles.timelineSeparator}>
                            <span
                              className={`${styles.timelineDot} gray40`}
                            ></span>
                            <span className={styles.timelineConnector}></span>
                          </div>
                          <div className={styles.timelineContent}>
                            <div>
                              <span
                                className={`${
                                  packageItem.stateCode !== "DTD" &&
                                  styles.eventOfTime
                                } gray40`}
                              >
                                {timeline.eventTime}
                              </span>
                              <span
                                className="gray60"
                                style={{
                                  marginLeft: "0.5rem",
                                }}
                              >
                                {timeline.status}
                              </span>
                            </div>
                            <div
                              className={
                                packageItem.stateCode !== "DTD" &&
                                styles.description
                              }
                            >
                              <span>{timeline.eventDescription}</span>
                              <span
                                style={{ paddingLeft: "5px", fontSize: "12px" }}
                              >
                                {timeline.eventLocation || ""}
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
        <PendingShipItems
          orderNumber={orderNumber}
          packages={packages}
          f={f}
          orderDetail={orderDetail}
          gotoProductDetail={gotoProductDetail}
        />
      </div>
    </div>
  );
}

const PendingShipItems = (props) => {
  const {f, orderDetail, gotoProductDetail, orderNumber, packages } = props;
  const [collapsed, setCollapsed] = React.useState(true);
  useEffect(() => {
    if (orderNumber && !packages?.length) {
      setCollapsed(false)
    }
  }, [orderNumber, packages])
  const toBeShippedList = orderDetail?.toBeShippedList;
  const isLocked = !!(orderDetail?.holdCodes)

  if (!toBeShippedList || !toBeShippedList.length) return null
  return (
    <div className={styles.packItemWrapper}>
      <div className={styles.packItemHeader}>
        <div className={styles.row1}>
          <div style={{paddingRight: '15px', paddingBottom: '4px'}}>
            <label className="head5">{f("PendingShip")}</label>
            <span>
              ({toBeShippedList.length} {f("ITEMS")})
            </span>
          </div>
          {isLocked ? <div
          style={
            {
              color: 'red',
              fontSize: '14px'
            }
          }>
            {f('OrderLockedMsg')}
          </div> : null}
        </div>
        <div className={styles.row2}>
          <span
            className="App-link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {collapsed ? <Add></Add> : <Remove></Remove>}
          </span>
        </div>
      </div>
      <div
        style={{paddingTop: '15px'}}
        className={
          collapsed
            ? `${styles.packItemContentWrapper} collapsed`
            : `${styles.packItemContentWrapper}`
        }
      >
        <div>
          {toBeShippedList?.map((product) => {
            const imgSrc = product.productImage
              ? product.productImage + "-650.jpg"
              : "/images/default-brand.jpg";
            return (
              <div
                key={product.catalogNumber}
                className={styles.productWrapper}
              >
                <div className={styles.imgWrapper}>
                  <img alt={product.description} src={imgSrc}></img>
                </div>
                <div className={styles.pcontentWrapper}>
                  <p
                    className="primaryLink bold vmargin14"
                    onClick={() => {
                      gotoProductDetail(product);
                    }}
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  ></p>
                  <div>
                    <label className="labelMedium">{f("CATALOGNUMBER")}:</label>
                    <span>{product.sku}</span>
                    <label
                      className="labelMedium"
                      style={{ paddingLeft: "1rem" }}
                    >
                      {f("QUANTITY")}:
                    </label>
                    <span>{product.quantity}</span>
                    {
                    isLocked ? null : <><label
                      className="labelMedium"
                      style={{ paddingLeft: "1rem" }}
                    >
                      {f("EstimateShippingDate")}:
                    </label>
                    <span style={{
                      fontSize: '14px'
                    }}>
                      {product.estimatedShippingDate || f("PendingShipDefault")}
                    </span></>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;
